import { SIGNING_CARD_STATUS, SIGNING_PROCESS } from 'src/constants'

const PT = Object.freeze({
  ages: {
    year: '%{smart_count} Ano |||| %{smart_count} Anos',
    month: '%{smart_count} Mês |||| %{smart_count} Meses',
    day: '%{smart_count} Dia |||| %{smart_count} Dias',
    hour: '%{smart_count} Hora |||| %{smart_count} Horas',
  },
  units: '%{smart_count} un.',
  dropdown: {
    terms: 'Termos e Condições',
    privacy: 'Política de Privacidade',
    faq: 'Perguntas Frequentes',
    logout: 'Sair',
    signer: 'e-ID Signer',
    transfers: 'Downloads',
    macDownload: 'https://cdn.kenetto.com/scribo/eidsigner/e-ID-Signer-1.0.1.pkg',
    windowsDownload: 'https://cdn.kenetto.com/scribo/eidsigner/e-ID-Signer-1.0.1.msi'
  },
  card: {
    sign: 'Assinar',
    reset: 'Reiniciar',
    prescription: {
      createdAt: 'Receita de %{date}',
      labels: {
        items: '',
        nameItem: 'Medicação/Dispositivo',
        packageItem: 'Nº Embalagens'
      }
    },
    progress: {
      [SIGNING_PROCESS.NOT_STARTED]: {
        1: 'Não iniciada'
      },
      [SIGNING_PROCESS.ADDING_SHARING]: {
        1: 'A adicionar os destinatários da prescrição'
      },
      [SIGNING_PROCESS.ERROR_ADDING_SHARING]: {
        1: 'Não foi possível adicionar os destinatários da prescrição'
      },
      [SIGNING_PROCESS.AUTHENTICATING_PRESCRIBER]: {
        1: 'A autenticar o prescritor na SPMS'
      },
      [SIGNING_PROCESS.ERROR_AUTHENTICATING_PRESCRIBER]: {
        1: 'Ocorreu um erro a autenticar o prescitor na SPMS'
      },
      [SIGNING_PROCESS.HASHING_PRESCRIPTION]: {
        1: 'A processar os dados da prescrição'
      },
      [SIGNING_PROCESS.ERROR_HASHING_PRESCRIPTION]: {
        1: 'Não foi possível processar os dados da prescrição'
      },
      [SIGNING_PROCESS.WAITING_SIGNING]: {
        1: 'A aguardar a assinatura do cartão...',
        2: 'Por favor verifique a janela de inserção do PIN'
      },
      [SIGNING_PROCESS.ERROR_WAITING_SIGNING]: {
        1: 'Ocorreu um erro com a assinatura, possivelmente um erro no pin ou excedeu o tempo para inserir o pin'
      },
      [SIGNING_PROCESS.ERROR_SIGNING_CANCELED_BY_USER]: {
        1: 'Assinatura cancelada. Para continuar, selecione "Reiniciar"'
      },
      [SIGNING_PROCESS.ADDING_DIGITAL_SIGNATURE]: {
        1: 'A verificar a assinatura...'
      },
      [SIGNING_PROCESS.ERROR_ADDING_DIGITAL_SIGNATURE]: {
        1: 'Erro a verificar a assinatura'
      },
      [SIGNING_PROCESS.PRESCRIBING]: {
        1: 'A registar a prescrição na SPMS'
      },
      [SIGNING_PROCESS.ERROR_PRESCRIBING]: {
        1: 'Não foi possível registar a prescrição na SPMS'
      },
      [SIGNING_PROCESS.PRESCRIBED]: {
        1: 'A prescrição foi registada'
      }
    }
  },
  cardStatus: {
    iconAlt: 'Ícone Cartão',
    tryAgain: 'Tentar Novamente',
    [SIGNING_CARD_STATUS.AVAILABLE]: {
      text: 'Pronto Para Assinar'
    },
    [SIGNING_CARD_STATUS.READER_UNAVAILABLE]: {
      text: 'Leitor Indisponível'
    },
    [SIGNING_CARD_STATUS.NO_PICTURE]: {
      text: 'Pronto Para Assinar'
    },
    [SIGNING_CARD_STATUS.UNKNOWN_CARD]: {
      text: 'Cartão Desconhecido'
    },
    [SIGNING_CARD_STATUS.CARD_UNAVAILABLE]: {
      text: 'Cartão Indisponível'
    },
    [SIGNING_CARD_STATUS.ERROR_READING_DATA]: {
      text: 'Erro a ler o Cartão'
    },
    [SIGNING_CARD_STATUS.CANCELED_DURING_PROCESSING]: {
      text: 'Leitura cancelada'
    },
    [SIGNING_CARD_STATUS.CARD_INTERNAL_ERROR]: {
      text: 'Erro a ler o cartão'
    },
    [SIGNING_CARD_STATUS.ERROR_READING_ID_DATA]: {
      text: 'Erro a ler o cartão'
    },
    [SIGNING_CARD_STATUS.READING_CARD]: {
      text: 'Leitura Em Curso'
    },
    [SIGNING_CARD_STATUS.SERVER_UNAVAILABLE]: {
      text: 'Software de Leitura Indisponível'
    },
    [SIGNING_CARD_STATUS.SERVER_UNREACHABLE]: {
      text: 'Sem Comunicação Com o Software de Leitura'
    },
    [SIGNING_CARD_STATUS.CONTACTING_SERVER]: {
      text: 'A Contactar o Software de Leitura'
    },
    [SIGNING_CARD_STATUS.OLD_VERSION]: {
      text: 'Versão desatualizada, por favor atualize o eID Signer'
    },
    [SIGNING_CARD_STATUS.SIGNING]: {
      text: 'A aguardar inserção de PIN'
    }
  },
  customDurationDescription: 'a partir de %{startDate} durante %{duration} |||| até ao final do tratamento',
  timeUnits: {
    minutes: 'minuto |||| minutos',
    hours: 'hora |||| horas',
    days: 'dia |||| dias',
    weeks: 'semana |||| semanas',
    months: 'mês |||| meses'
  },
  smartminutes: '%{smart_count} minuto |||| %{smart_count} minutos',
  smarthours: '%{smart_count} hora |||| %{smart_count} horas',
  smartdays: '%{smart_count} dia |||| %{smart_count} dias',
  smartweeks: '%{smart_count} semana |||| %{smart_count} semanas',
  smartmonths: '%{smart_count} mês |||| %{smart_count} meses',
  smartyears: '%{smart_count} ano |||| %{smart_count} anos',
  periodicallyDescription: '%{quantity} por %{unit} |||| %{quantity} de %{value} em %{value} %{unit}',
  atMealsDescription: {
    multipleMealsPeriods: {
      nA: {
        fasting: '%{quantity} em %{periods}',
        others: '%{quantity} ao %{periods}'
      },
      before: '%{quantity} antes do %{periods}',
      during: '%{quantity} durante o %{periods}',
      after: '%{quantity} depois do %{periods}'
    },
    singleMealPeriod: {
      nA: {
        fasting: '%{quantity} em jejum',
        breakfast: '%{quantity} ao pequeno-almoço',
        lunch: '%{quantity} ao almoço',
        snack: '%{quantity} ao lanche',
        dinner: '%{quantity} ao jantar',
        supper: '%{quantity} à ceia'
      },
      before: {
        breakfast: '%{quantity} antes do pequeno-almoço',
        lunch: '%{quantity} antes do almoço',
        snack: '%{quantity} antes do lanche',
        dinner: '%{quantity} antes do jantar',
        supper: '%{quantity} antes da ceia'
      },
      during: {
        breakfast: '%{quantity} durante o pequeno-almoço',
        lunch: '%{quantity} durante o almoço',
        snack: '%{quantity} durante o lanche',
        dinner: '%{quantity} durante o jantar',
        supper: '%{quantity} durante a ceia'
      },
      after: {
        breakfast: '%{quantity} depois do pequeno-almoço',
        lunch: '%{quantity} depois do almoço',
        snack: '%{quantity} depois do lanche',
        dinner: '%{quantity} depois do jantar',
        supper: '%{quantity} depois da ceia'
      }
    },
    and: 'e',
    fasting: 'jejum',
    breakfast: 'pequeno-almoço',
    lunch: 'almoço',
    snack: 'lanche',
    dinner: 'jantar',
    supper: 'ceia'
  },
  inTimeDescription: {
    multiple: {
      hours: '%{quantity} às %{multiple}',
      daysOfWeek: {
        sunday: '%{quantity} ao %{multiple}',
        others: '%{quantity} à %{multiple}'
      },
      daysOfMonth: '%{quantity} nos dias %{multiple} do mês'
    },
    single: {
      hours: '%{quantity} às %{single}',
      daysOfWeek: '%{quantity} a cada %{single}',
      daysOfMonth: '%{quantity} no dia %{single} do mês'
    },
    hoursSuffix: '%{hours}h',
    sunday: 'domingo',
    monday: 'segunda-feira',
    tuesday: 'terça-feira',
    wednesday: 'quarta-feira',
    thursday: 'quinta-feira',
    friday: 'sexta-feira',
    saturday: 'sábado',
    and: 'e'
  },
  sosDescription: {
    description: '%{quantity} em SOS',
    if: 'se %{if}',
    until: 'até %{until}'
  },
  oneShotDescription: {
    description: '%{quantity}'
  },
  posology: {
    label: 'Posologia',
    duration: 'Duração do tratamento:',
    longDuration: 'Tratamento de longa duração',
    for: 'durante',
    instructions: 'Instruções:',
    error: 'Preencha a posologia'
  },
})

export default PT
